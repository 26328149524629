//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify';

export default {
  name: 'iFrameDialog',
  props: ["show_status","url"],
  data () {
    return {
      loading: false,
      fixed: false,
      screenHeight: 0,
    }
  },
  methods: {
    onHide() {
      console.log("onHide");
      this.$emit("onHide", "");
      //this.$parent.scode_dialog_show = false;
    },
  },
  mounted () {
      if (this.IsFullScreen) {
        this.screenHeight = screen.height - 120;
      } else {
        this.screenHeight = screen.height - 230;
      }
      //alert("URL:" + this.url);
      //alert(" screenHeight : " + this.screenHeight);
      //console.log('AdminPasswordDialog mounted.');
  },
  watch: {
    show_status: function () {
        //console.log("fixed :" + this.fixed);
        this.fixed = this.show_status;
        if (this.IsFullScreen) {
          this.screenHeight = screen.height - 120;
        } else {
          this.screenHeight = screen.height - 230;
        }
        //document.myform.submit();
        //this.$refs.myform.submit();
        //alert("screenHeight : " +  this.screenHeight);
    },
    IsFullScreen: function() {
        if (this.IsFullScreen) {
          this.screenHeight = screen.height - 120;
        } else {
          this.screenHeight = screen.height - 230;
        }
    }

  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      IsFullScreen() {
        return (!!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement))
      }
  },
  created () {
    /**
     * The reason we have this here
     * is that the type needs to be
     * registered before using it.
     *
     * The best place would be a boot file instead
     * of a .vue file, otherwise it'll keep on
     * registering it every time your component
     * gets to be used :)
     */
  }
 
}
