//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { openURL } from 'quasar'
import { get } from 'vuex-pathify'
import store from '../store/index'
import MbscDatePicker from '@/components/MbscDatePicker.vue'
import EStoreOrdersDetailDialog from '@/components/EStoreOrdersDetailDialog.vue'
import PaymentDialog from '@/components/PaymentDialog.vue'
import QrCode30Dialog from '@/components/QrCode30Dialog.vue'
import IFrameDialog from '@/components/IFrameDialog.vue'

export default {
  components: {
    MbscDatePicker,
    EStoreOrdersDetailDialog,
    PaymentDialog,
    QrCode30Dialog,
    IFrameDialog
  },
  data () {
    return {
      first_time_notify: true,
      new_order_count: 0,
      payment_dialog_visible: false,
      report_url: "",
      iframe_dialog_show: false,
      init: false,
      orders_no:"",
      total_amount_net: 0,
      estore_orders_detail_dialog_show: false,
      payment_dialog_show: false,
      qr_dialog_show: false,
      searchText:"",
      from_date:"",
      to_date:"",
      loading: false,
      query:"1",
      total_record: 0,
      total_page: 1,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'doc_no', align: 'left', label: 'เลขที่ใบเสร็จฯ / เลขที่ใบสั่งซื้อ', field: 'doc_no'},
        { name: 'id_card', align: 'left', label: 'เลขที่บัตรประชาชน / ชื่อ-นามสกุล', field: 'id_card'},
        { name: 'date', align: 'center', label: 'วันที่', field: 'date'},
        { name: 'bill_status', align: 'center', label: 'สถานะ', field: 'bill_status'},
        //{ name: 'approved_date', align: 'center', label: 'วันที่อนุมัติ', field: 'approved_date'},
        { name: 'total_amount', align: 'right', label: 'ค่าสินค้า', field: 'total_amount' },
        { name: 'delivery_cost', align: 'right', label: 'ค่าจัดส่ง', field: 'delivery_cost' },
        //{ name: 'discount', align: 'right', label: 'ส่วนลด', field: 'discount' },
        { name: 'total_amount_net', align: 'right', label: 'ยอดสุทธิ', field: 'total_amount_net' }
      ],
    }
  },
  methods: {
       triggerNegative (msg_text) {
            this.$q.notify({
              type: 'negative',
              message: msg_text,
              classes:'app-font'
            })
       },
       confirmCancelOrders(poNo, id_no, name_t) {
          let self = this;
          this.$q.dialog({
              title: "ยืนยัน ยกเลิกการสั่งซื้อ",
              message: 
                " เลขที่ใบสั่งชื้อ : <b>" + poNo + "</b><br>"+
                " ผู้ซื้อ : <b>" + id_no + " - " + name_t + "</b>" ,
              persistent: true,
              html: true,
              class: "app-font",
              style: "background-color:#FFFFFF; color:#000000;",
              ok: {
                label: "ตกลง",
                color: 'positive',
                icon: 'check'
                    },
                    cancel: {
                label: "ยกเลิก",
                color: 'negative',
                icon: 'close'
              },
              }).onOk(() => {
                self.postCancelOrders(poNo, id_no);
                // console.log('>>>> OK')
              }).onOk(() => {
                // console.log('>>>> second OK catcher')
              }).onCancel(() => {
                // console.log('>>>> Cancel')
              }).onDismiss(() => {
                // console.log('I am triggered on both OK and Cancel')
          })
      },
      postCancelOrders(poNo, id_no) {
        console.log(poNo, id_no);
        const params = new URLSearchParams();
        params.append("number", poNo);
        params.append("id_card", id_no);
        let self = this;
        self.axios({
          headers: {
            'Authorization': self.jwt_token
          },
          method: "post",
          url: self.url_api + "MemberClient/eStore.Billing.Cancel.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let cancel_status = responseDoc.getElementsByTagName('cancel_status')[0].textContent;
          if (cancel_status == "0") {
              self.triggerNegative("ไม่สามารถทำการยกเลิกได้");
          }
          self.getData();
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      gotoRegisCode() {
        this.$router.push({ name:"Registration"});
      },
      refresh (done) {
        setTimeout(() => {
          this.getData();
          done();
        }, 1000)
      },
      clearFilter: function () {
        this.searchText = "";
        this.getData();
      },
      openDetailDialog: function(number) {
        this.orders_no = number;
        this.estore_orders_detail_dialog_show = true;
      },
      estore_orders_detail_dialog_hide () {
        this.estore_orders_detail_dialog_show = false;
        //console.log("hide set estore_orders_detail_dialog_show = " + this.estore_orders_detail_dialog_show);
      },
      open_payment_dialog (number, qr_ready, total_amount_net) {
        //alert(" total_amount_net : " + total_amount_net );
        //alert("qr_ready : " + qr_ready);
        this.orders_no = number;
        this.total_amount_net = total_amount_net;
        if (qr_ready == '0') {
            this.payment_dialog_show = true;
        } else {
            this.qr_dialog_show = true;
        }
      },
      payment_dialog_hide () {
        this.payment_dialog_show = false;
        this.getData();
      },
      qr_dialog_hide() {
        this.qr_dialog_show = false;
        this.getData();
      },
      iframe_dialog_hide: function () {
          this.iframe_dialog_show = false;
      },
      /*
      exportPDF: function(number) {
          this.report_url = this.url_api + "pdf/eStore.Orders.Print.PDF.aspx?number="+number+"";
          this.iframe_dialog_show = true;
      },
      */
      exportPDF: function(number) {
          let self = this;
          let actionUrl = self.url_api + "pdf/eStore.Orders.Print.PDF.aspx";
          let windowName = "ใบเสร็จรับเงิน/ใบกำกับภาษี";
          let windowFeatures = "";
          let data = {
                        Authorization: self.jwt_token,
                        number: number
                     };
          this.windowOpenWithPost(actionUrl, windowName, windowFeatures, data);
      },
      openDeliverInfo: function( deliver_code, deliver_url ) {
        openURL(deliver_url + deliver_code);
      },
      trackDelivery: function(deliver_id, deliver_code, deliver_url) {
            let self = this;
            let actionUrl = self.url_api + "MemberClient/eStore.Delivery.Track.aspx";
            let windowName = "ตรวจสอบสถานะการจัดส่ง";
            let windowFeatures = "";
            let data = {
                          Authorization: self.jwt_token,
                          deliver_id: deliver_id,
                          deliver_code: deliver_code,
                          deliver_url: deliver_url
                      };
            this.windowOpenWithPost(actionUrl, windowName, windowFeatures, data);
      },
      /*
      exportExcel: function() {
          let self = this;
          openURL(self.url_api + 'Bonus/Report.Daily.Bonus.ByPeriodDate.Excel.aspx')    
      },*/
      requestRowClick: function(selected_mcode, selected_row) {
          this.current_row = selected_row.rownum;
          //console.log("row click :" + selected_mcode);
          if (this.selected.mcode !== selected_mcode) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
            //this.$emit("onSeleted", selected_row);
          }
      },
      //Bonus/Report.Daily.Bonus.Getlastdate.aspx
      fetchDateRange: function () {
        //console.log("fetch bonus date");
        const params = new URLSearchParams();
        params.append("mcode", this.session_mcode);
        let self = this;
        self.axios({
          headers: {
            'Authorization': self.jwt_token
          },
          method: "post",
          url: self.url_api + "MemberClient/eStore.Billing.GetDateRange.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let start_date = responseDoc.getElementsByTagName('startdate')[0].textContent;
          let end_date = responseDoc.getElementsByTagName('enddate')[0].textContent;
          //console.log("start_date :" + start_date + " end_date : " + end_date);
          self.from_date = start_date;
          self.to_date = end_date;
          self.getData();
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      editMode() {
        //console.log("click");
      },
      updateDate(e, title) {
        //console.log("update mdate to :" + e);
        switch (title) {
          case "ตั้งแต่วันที่":
            this.from_date = e;
            break;
          case "ถึงวันที่":
            this.to_date = e;
            break;  
        }
      },
      getData: function () {
        var self = this;
        self.loading = true;
        self.rowsPerFetch = self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('pagesize', self.rowsPerFetch);
        params.append('pageid', self.pageID);
        params.append('from_date', self.from_date);
        params.append('to_date', self.to_date);
        params.append('findword', self.searchText);
        self.axios({
          headers: {
            'Authorization': self.jwt_token
          },
          method: 'post',
          timeout: 0, // Let's say you want to wait at least 4 mins,
          url: self.url_api + 'MemberClient/eStore.GetBillHistory.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            let new_order_count = parseInt(responseDoc.getElementsByTagName('new_order_count')[0].textContent);
            /*
            console.log(responseDoc.getElementsByTagName('json')[0].textContent);
            console.log("record count " + record_count.toString());
            console.log("page count " + responseDoc.getElementsByTagName('pagecount')[0].textContent);
            */

            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            } else {
              self.datalist = [];
              self.selected = [];
            }
            self.total_record = record_count;
            self.total_page = page_count;
            self.loading = false;
            self.init = true;

            if (new_order_count > 0) {
              if (self.first_time_notify) {
                  self.payment_dialog_visible = true;
                  self.first_time_notify = false;
              }
              self.new_order_count = new_order_count;
            }
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      },

  },
  mounted: function () {
      //console.log('Component mounted.');
      this.fetchDateRange();
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_date: get('SESSION_DATE')
  },
  watch: {
    pageID: function () {
      if (this.init) {
        this.getData();
      }  
    },
    from_date: function () {
      if (this.init) {
        this.getData();
      }  
    },
    to_date: function () {
      if (this.init) {
        this.getData();
      }  
    }
  },
  created () {
      //console.log('Component created.');
      store.set("APP_TOOLBAR_TITLE","ประวัติการสั่งซื้อสินค้า");
      this.$q.loading.show();
  }    
}   
