//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import store from '../store/index'
import { get } from 'vuex-pathify'
import EStoreAddToCartDialog from '@/components/EStoreAddToCartDialog.vue'

export default {
  components: {
    EStoreAddToCartDialog
  },
  name: "EstoreProductList",
  props: ["cart_no","new_member"],
  data () {
    return {
      tableStyle: 'list',
      current_prodcode: "",
      color_index: 0,
      color: [
        { code: "#DEDCD8" },
        { code: "#E2C6B7" },
        { code: "#F7AFB9" },
        { code: "#DBB6DE" },
        { code: "#FFFFE9" },
        { code: "#D0F7FC" },
        { code: "#D5E6CC" },
        { code: "#B6E8B2" },
        { code: "#F3ECA0" },
        { code: "#FCB6AB" },
      ],
      ui_size: "",
      item_info: { 
                  cart_no: "", 
                  prodcode: "",  
                  prodname: "",
                  price: "0",
                  pv: "0",
                  minimum: "0",
                  maximum: "0",
                   /*
                   cart_no: this.cart_no, 
                   prodcode: this.selected[0].prodcode,  
                   prodname: this.selected[0].prodname,
                   pv: this.selected[0].pv,
                   price: this.selected[0].price
                   */
                 },
      estore_add_to_cart_dialog_show: false,
      product_img_url: "",
      prodimage_exists: 0,
      pagination: {
        rowsPerPage: 0
      },
      loading: false,
      query:"1",
      datalist: [],
      selected: [],
      columns: [
        { name: 'prodcode', align: 'left', label: 'รหัสสินค้า', field: 'prodcode'},
        { name: 'prodname', align: 'left', label: 'ชื่อสินค้า', field: 'prodname'},
        { name: 'pv', align: 'right', label: 'PV', field: 'pv'},
        { name: 'price', align: 'right', label: 'ราคา', field: 'price'},
        { name: 'color_code', field: "color_code" }
      ],
      columns_mobile: [
        { name: 'prodname', align: 'left', label: 'รายละเอียดสินค้า', field: 'prodname'},
      ]
    }
  },
  methods: {
      refresh (done) {
        setTimeout(() => {
          this.getData();
          done();
        }, 1000)
      },
      setUISize() {
          //if (this.$q.screen.width > 1023) { 
          if (this.$q.screen.width > 800) {
          //console.log('screen large');
          this.ui_size = "D";
          }
          else {
          //console.log('screen small');
          this.ui_size = "M";
          }
      },
      gotoCartTab () {
        this.$emit("changeTab","cart");
      },
      open_add_to_cart_dialog: function(item) {
        this.item_info.cart_no = this.cart_no;
        this.item_info.prodcode = item.prodcode;
        this.item_info.prodname = item.prodname;
        this.item_info.price = item.price;
        this.item_info.pv = item.pv;
        this.item_info.minimum = item.minimum;
        this.item_info.maximum = item.maximum;
        this.estore_add_to_cart_dialog_show = true;
      },
      estore_add_to_cart_dialog_hide () {
        this.estore_add_to_cart_dialog_show = false;
        //console.log("hide set estore_add_to_cart_dialog_show = " + this.estore_add_to_cart_dialog_show);
      },
      requestRowClick: function(selected_prodcode, selected_row) {
          //console.log("row click :" + selected_mcode);
          if (this.selected.prodcode !== selected_prodcode) {
            this.selected.splice(0,1); //delete item from selected array 
            this.selected.push(selected_row); //add item from selected array 
            let prodcode = selected_prodcode;
            let product_img_filename;
            /*
            if (prodcode.indexOf("-") >= 0) {
              product_img_filename = prodcode.substring(0, prodcode.search("-"));
            } else {
              product_img_filename = prodcode;
            }
            */
            product_img_filename = prodcode;
            this.prodimage_exists = selected_row.prodimage_exists;
            //alert("this.prodimage_exists : " + this.prodimage_exists);
            this.product_img_url = this.url_api.replace("api","product") + product_img_filename + ".jpg";
          }
      },
      getData: function () {
        var self = this;
        self.loading = true;
        const params = new URLSearchParams();
        params.append('new_member', self.new_member);
        self.rowsPerFetch = 200;//self.appRowsPerFetch;
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 0, // Let's say you want to wait at least Unlimits mins,
          url: self.url_api + 'MemberClient/eStore.Product.List.JSON.aspx',
          data: params
        })
        .then(function (response) {
            //console.log(response);
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
              let prodcode = self.selected[0].prodcode;
              let product_img_filename;
              if (prodcode.indexOf("-") >= 0) {
                product_img_filename = prodcode.substring(0, prodcode.search("-"));
              } else {
                product_img_filename = prodcode;
              }
              self.prodimage_exists = self.selected[0].prodimage_exists;
              self.product_img_url = self.url_api.replace("api","product") + product_img_filename + ".jpg";
            } else {
              self.datalist = [];
              self.selected = [];
            }
           
            self.loading = false;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  
  },
  mounted () {
      //let self = this;
      //console.log('EStoreOrdersDetail Dialog mounted.');
      this.getData();
  },
  watch: {
    "$q.screen.width"() {
      this.setUISize()
    },
    /*
    show_status: function () {
      console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
    }*/
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      blank_img: function() {
          let product_img_url = this.url_api.replace("api","product") + "blank.png";
          return product_img_url
      },
      tr_color: function() {
        
        //console.log(prodcode.substring(0, prodcode.search("-")));
        /*
        prodcode = prodcode.substring(0, prodcode.search("-"));
        if (this.current_prodcode != prodcode) {
           alert("prodcode: " + prodcode);
           this.color_index = this.color_index + 1;
           this.current_prodcode = prodcode;
        }
        
        console.log("background-color:"+ this.color[this.color_index].code +"; color:#000000;");
        
        //return "background-color:"+ this.color[this.color_index].code +"; color:#000000;";
        return "background-color:#000000 ; color:#ffffff;";
        */
        return ""
      },
      cardContainerClass () {
        if (this.$q.screen.gt.xs) {
          return 'grid-masonry grid-masonry--' + (this.$q.screen.gt.sm ? '3' : '2')
        }

        return void 0
      },


  },
  created () {
    this.setUISize();
    store.set("APP_TOOLBAR_TITLE","สั่งซื้อสินค้า");
    //console.log('Component created.');
  }
}
