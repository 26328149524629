//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify';

export default {
  name: 'UplineTreeFindDialog',
  props: ["show_status", "mcode_main", "search_text"],
  data () {
    return {
      loading: false,
      total_record: 0,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      findWord: '',
      current_row: 1,
      min_row: 0,
      max_row: 0,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum' },
        { name: 'lvl', align: 'center', label: 'ระดับ', field: 'lvl'},
        { name: 'mcode', align: 'center', label: 'รหัสสมาชิก', field: 'mcode'},
        { name: 'name_t', align: 'left', label: 'ชื่อสมาชิก', field: 'name_t'},
        { name: 'mdate',  align: 'center', label: 'วันที่สมัคร', field: 'mdate' },
        { name: 'rank',  align: 'center', label: 'ตำแหน่ง', field: 'rank' },
        { name: 'up_code',  align: 'center', label: 'รหัสอัพไลน์', field: 'up_code' },
        { name: 'ppvtotal',  align: 'right', label: 'PV สะสม', field: 'ppvtotal' }
      ],
      fixed: false
    }
  },
  methods: {
    requestRowClick: function(selected_mcode, selected_row) {
        this.current_row = selected_row.rownum;
        //console.log("row click :" + selected_mcode);
        if (this.selected.mcode !== selected_mcode) {
          this.selected.splice(0,1);
          this.selected.push(selected_row);
          this.$emit("onSeleted", selected_row);
        }
    },
    fetchDataList: function () {
      let self = this;
      self.loading = true;
      const params = new URLSearchParams();
      params.append('mcode_main', this.mcode_main);
      params.append('search', this.search_text);
      //let i; 
      //let result;
      /*
      console.log("mcode_main:"+ this.mcode_main);
      console.log("findWord:"+ this.search_text);
      */
      self.axios({
        method: 'post',
        headers: {
          'Authorization': self.jwt_token
        },
        timeout: 0, // Let's say you want to wait at least 6 mins
        url: self.url_api + 'MemberClient/GetMcodeDownline.JSON.aspx',
        data: params
      })
      .then(function (response) {
          //console.log("response data");
          //console.log("result :" + response.data);
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let checkTeam = responseDoc.getElementsByTagName('team')[0].textContent;
          if (checkTeam == "list") {
            self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
            self.selected = [];
            self.current_row = 1;
            //self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            //self.current_row = self.selected[0].rownum;
            self.total_record = self.datalist.length;
            if (self.show_status) {
                self.loading = false;
                self.fixed = self.show_status;
                setTimeout( function() { 
                    self.$q.loading.hide();
                }, 3000);
            }
          } else {
            self.datalist = [];
            self.selected = [];
            self.current_row = 1;
            self.total_record = 0;
            if (self.show_status) {
                self.loading = false;
                self.fixed = self.show_status;
                setTimeout( function() { 
                    self.$q.loading.hide();
                }, 4000);
            }
          }
         
      })
      .catch(function (error) {
          console.log("Error :" + error);
          if (self.show_status) {
            self.loading = false;
            self.$q.loading.hide();
          }
      });
    }, 
    onHide() {
      //console.log("onHide");
      this.$emit("onHide", "");
      //this.$emit("onSeleted",this.selected[0]);
      //this.$parent.scode_dialog_show = false;
    }
    /*
    ,
    // when props.ok() gets called
    onOk (data) { },

    // when props.cancel() gets called
    onCancel () { },

    // when we show it to the user
    onShow () { },

    // when it gets hidden
    onHide () { },

    // custom handler
    async choose (okFn, hero) {
      if (this.name.length === 0) {
        this.$q.dialog({
          title: 'Please specify your name!',
          message: `Can't buy tickets without knowing your name.`
        })
      }
      else {
        await okFn()
        this.$q.notify(`Ok ${this.name}, going with ${hero}`)
      }
    }
    */
  },
  mounted () {
      //let self = this;
      //console.log('UplineTreeFind Dialog mounted.');
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      if (this.show_status && this.search_text != "") {
        this.fetchDataList();
      } else {
        this.fixed = this.show_status;
      }
      /*
       if (this.show_status) {
        this.selected.splice(0,1);
      }
      */
    },
    /*
    pageID: function () {
      //this.fetchDataList();
    },
    /*
    fixed: function() {
    },
    /*
    search_text: function () {
      this.fetchDataList();
    }
    */
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
  }
 
}
