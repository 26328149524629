//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify';

export default {
  name: 'ExtendBillDialog',
  props: ["show_status", "mtype"],
  data () {
    return {
      loading: false,
      refer_number: "",
      refer_id: "",
      expiredate : "",
      new_expiredate : "",
      total_record: 0,
      total_page: 1,
      total_page_suffix: "",
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      findWord: '',
      current_row: 1,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'number', align: 'center', label: 'เลขที่ใบเสร็จ', field: 'number'},
        { name: 'prodid', align: 'center', label: 'รายการที่', field: 'prodid'},
        { name: 'prodcode', align: 'center', label: 'รหัสสินค้า', field: 'prodcode'},
        { name: 'prodname', align: 'left', label: 'ชื่อสินค้า', field: 'prodname'},
        { name: 'q_remain', align: 'center', label: 'จำนวนคงเหลือ', field: 'q_remain'},
        /*
        { name: 'id_card', align: 'center', label: 'เลขที่บัตรประชาชน', field: 'id_card'},
        { name: 'name_t', align: 'left', label: 'ชื่อ-นามสกุล', field: 'name_t'},
        */
      ],
      /* 
      customDialogModel: false,
      name: ''
      */
      fixed: false
    }
  },
  methods: {
    previous_page: function() {
      if (parseInt(this.pageID) > 1) {
        this.pageID = parseInt(this.pageID) - 1;
      }
    },
    next_page: function() {
      if (parseInt(this.pageID) < parseInt(this.total_page)) {
        this.pageID = parseInt(this.pageID) + 1;
      }
    },
    requestRowClick: function(selected_row) {
      //alert("เลขที่บิล : " + selected_row.number + "\n" + " วันพ้นภาพ : " + this.expiredate + " -> " + " ขยายวันพ้นสภาพเป็น : " + this.new_expiredate);
      this.refer_number = selected_row.number;
      this.refer_id = selected_row.prodid;
      this.$q.dialog({
            title: "ยืนยันการต่ออายุสมาชิก รหัส : <b>" + this.session_mcode + "</b>",
            message: " เลขบัตรประชาชน : <b>" + this.session_id_no + "</b><br>" +
                    " ชื่อ-นามสกุล : <b>" + this.session_mname + "</b></br>" + 
                    " ใช้เครดิตการต่ออายุในใบเสร็จฯ เลขที่ : <b>" + selected_row.number +  "</b></br>" + 
                    " รายการที่  : <b>" + selected_row.prodid + " - " + selected_row.prodcode +  " (" + selected_row.prodname + ")</b></br>" + 
                    " ขยายวันพ้นสภาพจากวันที่ : <b>" + this.expiredate + "  -> " + this.new_expiredate + "</b>",
            persistent: true,
            html: true,
            class: "app-font",
            style: "background-color:#FFFFFF; color:#000000;",
            ok: {
              label: "ตกลง",
              color: 'positive'
            },
            cancel: {
              label: "ยกเลิก"
            },
          }).onOk(() => {
            this.post_extend();
            // console.log('>>>> OK')
          }).onOk(() => {
            // console.log('>>>> second OK catcher')
          }).onCancel(() => {
            //this.submit_btn_disable = false;
            // console.log('>>>> Cancel')
          }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
          });
      
      //this.fixed = false;
    },
    post_extend() {
        let self = this;
        self.$q.loading.show();
        const params = new URLSearchParams();
        params.append("mcode", self.session_mcode);
        params.append('refer_number', self.refer_number);
        params.append('refer_id', self.refer_id);
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/Member.Extend.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let result = responseDoc.getElementsByTagName('update_status')[0].textContent;
          let result_id = responseDoc.getElementsByTagName('id')[0].textContent;
          let result_date = responseDoc.getElementsByTagName('expiredate')[0].textContent;
          let result_txt;
          if (result_id != "0" || result !="OK") {
            result_txt = "บันทึกข้อมูลเรียบร้อย ขยายวันพ้นสภาพ รหัสสมาชิก : " + self.session_mcode + " เป็นวันที่ : " + result_date;
            self.triggerPositive(result_txt);
            self.$emit("onUpdated", result_id);
            self.$q.loading.hide();
          } else {
            result_txt = "ระบบไม่สามารถบันทึกข้อมูลได้";
            self.triggerNegative(result_txt);
            self.$q.loading.hide();
          }
        })
        .catch(function (error) {
          self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
          console.log("Error :" + error);
          self.$q.loading.hide();
          self.submit_btn_disable = false;
        });
    },

    fetchDataList: function () {
      let self = this;
      self.loading = true;
      const params = new URLSearchParams();
      params.append('pagesize', self.rowsPerFetch);
      params.append('pageid', self.pageID);
      params.append('orders_by_id_card', self.session_id_no);
      params.append('mtype', self.mtype);
      params.append('mcode', self.session_mcode);
      //console.log("fetch bill list");
      self.axios({
        method: 'post',
        headers: {
          'Authorization': self.jwt_token
        },
        timeout: 0, // Let's say you want to wait at least 4 mins
        url: self.url_api + 'MemberClient/ExtendBill.List.JSON.aspx',
        data: params
      })
      .then(function (response) {
        let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
        let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
        let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
        self.expiredate = responseDoc.getElementsByTagName('expiredate')[0].textContent
        self.new_expiredate = responseDoc.getElementsByTagName('new_expiredate')[0].textContent;
        if (record_count > 0) {
          self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
          //self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
          self.total_record = record_count;
          self.total_page = page_count;
          self.loading = false;
        } else {
          self.datalist = [];
          self.selected = [];
          self.total_record = 0;
          self.total_page = 1;
          self.loading = false;
        }
      })
      .catch(function (error) {
          console.log("Error :" + error);
          self.loading = false;
      });
    },
    onHide() {
      //console.log("onHide");
      this.$emit("onHide", "");
      //this.$parent.scode_dialog_show = false;
    },
    triggerPositive (msg_text) {
      this.$q.notify({
        type: 'positive',
        progress: true,
        message: msg_text,
        classes:'app-font'
      });
    },
    triggerNegative (msg_text) {
      this.$q.notify({
        type: 'negative',
        message: msg_text,
        classes:'app-font'
      })
    },   
    /*
    ,
    // when props.ok() gets called
    onOk (data) { },

    // when props.cancel() gets called
    onCancel () { },

    // when we show it to the user
    onShow () { },

    // when it gets hidden
    onHide () { },

    // custom handler
    async choose (okFn, hero) {
      if (this.name.length === 0) {
        this.$q.dialog({
          title: 'Please specify your name!',
          message: `Can't buy tickets without knowing your name.`
        })
      }
      else {
        await okFn()
        this.$q.notify(`Ok ${this.name}, going with ${hero}`)
      }
    }
    */
  },
  mounted () {
      //console.log('Center Dialog mounted.');
      //this.fetchDataList();
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
      if (this.fixed) {
        this.fetchDataList();
      }
      /*
      if (this.show_status) {
        this.selected.splice(0,1);
      }
      */
    },
    mtype : function () {
      this.fetchDataList();
    },
    pageID: function () {
      this.fetchDataList();
    }
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_id_no: get('SESSION_ID_NO'),
      session_mname: get('SESSION_MCODE_NAME'),
  }
 
}
