//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'

export default {
  name: "EPointTransferDialog",
  props: ["show_status"],

  data () {
    return {
      submit_btn_disable: false,
      isPwd: true,
      fixed: false,
      mcode_current: "",
      loading: false,
      to_mcode:"",
      to_name_t:"",
      epoint_password:"",
      epoint_transfer: 0,
      epoint_balance: 0,
      remark: "",
      full_width_boolean: false,
      dialog_style: ""
    }
  },
  methods: {
      setDialogSize() {
        //if (this.$q.screen.width > 1023) { 
        if (this.$q.screen.width > 800) {
          console.log('screen large');
          this.full_width_boolean = false;
          this.dialog_style = "background-color:#f5fafe; width: 500px; max-width: 50vw;";
        }
        else {
          console.log('screen small');
          this.full_width_boolean = true;
          this.dialog_style = "";
        }
      },
      validate_transfer_value() {
        if (parseFloat(this.epoint_transfer) > (parseFloat(this.epoint_balance))) {
          this.epoint_transfer = this.epoint_balance;
        }
      },
      triggerPositive (msg_text) {
        this.$q.notify({
          type: 'positive',
          progress: true,
          message: msg_text,
          classes:'app-font'
        });
      },
      triggerNegative (msg_text) {
        this.$q.notify({
          type: 'negative',
          message: msg_text,
          classes:'app-font'
        })
      },
      clearForm: function() {
        this.to_mcode = "";
        this.to_name_t = "";
        this.remark = "";
        this.epoint_transfer = 0;
        this.epoint_password = "";
      },
      paddingMcode: function() {
        if (this.to_mcode.length < this.mcode_length) {
          this.to_mcode = this.paddingCode(this.to_mcode);
        }
        this.fetchMcodeName();
      },
      onHide() {
        //console.log("onHide");
        this.$emit("onHide", "");
        //this.$parent.scode_dialog_show = false;
      },
      onShow() {
        this.to_mcode = "";
        this.to_name_t = "",
        this.epoint_password = "";
        this.epoint_transfer = 0;
        this.epoint_balance = 0;
        this.remark = "";
        this.fetchEPointBalance();
        /*
        this.mcode_current = this.mcode_start;
        if (this.mcode_start != "") {
          this.$q.loading.show();
          this.getData(); 
        }*/
      },
      fetchEPointBalance: function () {
        let self = this;
        const params = new URLSearchParams();
        params.append('mcode', self.session_mcode);
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 0,
          url: self.url_api + "MemberClient/ePoint.GetBalance.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.epoint_balance = responseDoc.getElementsByTagName('epoint')[0].textContent;
          self.$q.loading.hide();
          //console.log("epoint_balance : " + self.epoint_balance);
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      fetchMcodeName: function () {
        let self = this;
        const params = new URLSearchParams();
        params.append('mcode', self.to_mcode);
        params.append('from_mcode', self.session_mcode);
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 0,
          url: self.url_api + "MemberClient/ePoint.Transfer.GetMemberName.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.to_name_t = responseDoc.getElementsByTagName('name_t')[0].textContent;
          //console.log("to_name_t : " + self.to_name_t);
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      confirm_postData: function () {
        if (this.to_name_t == "" || this.epoint_transfer == 0 || this.epoint_password =="") {
            return
        } else {
            if (parseFloat( this.replaceAll(this.epoint_transfer,",","") ) <= parseFloat(  this.replaceAll(this.epoint_balance,",","")  )) {
                let self = this;
                this.$q.dialog({
                    title: "ยืนยันการการโอน",
                    message: " ผู้รับโอน : <b>" + self.to_mcode + " - " + self.to_name_t + "</b></br>" +
                            " ยอดโอน : <b>" + self.formatnumber(parseFloat(self.epoint_transfer),2) + "</b></br>" +
                            " บันทึก : " + self.remark,
                    persistent: true,
                    html: true,
                    class: "app-font",
                    style: "background-color:#FFFFFF; color:#000000;",
                    ok: {
                      label: "ตกลง",
                      color: 'positive'
                    },
                    cancel: {
                      label: "ยกเลิก"
                    },
                  }).onOk(() => {
                    this.postData();
                    // console.log('>>>> OK')
                  }).onOk(() => {
                    // console.log('>>>> second OK catcher')
                  }).onCancel(() => {
                    // console.log('>>>> Cancel')
                  }).onDismiss(() => {
                    // console.log('I am triggered on both OK and Cancel')
                });
            } else {
                this.triggerNegative("ยอด e-Point มีไม่พอ");
            }
        }
      },
      postData: function () {
        var self = this;
        self.submit_btn_disable = true;
        self.loading = true;
        const params = new URLSearchParams();
        params.append('from_mcode', self.session_mcode);
        params.append('to_mcode', self.to_mcode);
        params.append('epoint', self.epoint_transfer);
        params.append('password', self.epoint_password);
        params.append('remark', self.remark);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 0,
          url: self.url_api + 'MemberClient/ePoint.Transfer.Insert.JSON.aspx',
          data: params
        })
        .then(function (response) {
            //console.log(response);
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let result = responseDoc.getElementsByTagName('result_value')[0].textContent;
            let result_txt = responseDoc.getElementsByTagName('result_txt')[0].textContent;
            if (result == "1") {
              self.triggerPositive(result_txt);
              self.clearForm();
              self.$parent.getData();
            } else {
              self.triggerNegative(result_txt);
            }
            self.fetchEPointBalance();
            self.loading = false;
            self.$q.loading.hide();
            self.submit_btn_disable = false;
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
            self.loading = false;
            self.$q.loading.hide();
            self.submit_btn_disable = false;
        });
      }
    /*
    ,
    // when props.ok() gets called
    onOk (data) { },

    // when props.cancel() gets called
    onCancel () { },

    // when we show it to the user
    onShow () { },

    // when it gets hidden
    onHide () { },

    // custom handler
    async choose (okFn, hero) {
      if (this.name.length === 0) {
        this.$q.dialog({
          title: 'Please specify your name!',
          message: `Can't buy tickets without knowing your name.`
        })
      }
      else {
        await okFn()
        this.$q.notify(`Ok ${this.name}, going with ${hero}`)
      }
    }
    */
  },
  mounted () {
      
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
    },
    "$q.screen.width"() {
      this.setDialogSize()
    }
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN')
  },
  created () {
      this.setDialogSize();
  }    
 
}
