//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


//import { openURL } from 'quasar'
import { get } from 'vuex-pathify'
import store from '../store/index'

export default {
  data () {
    return {
      all_mcode: false,
      mcode: "",
      init: false,
      submit_btn_disable: true,
      loading: false,
      query:"1",
      total_record: 0,
      total_page: 1,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      model: {label: "", value: ""},
      roundList: [],
      roundOptions:this.roundList,
      from_round_selected: {label: "", value: "" },
      to_round_selected: {label: "", value: "" },
      data_key: 'rownum',
      dense: false,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'round', align: 'center', label: 'รอบจำหน่าย', field: 'round'},
        { name: 'descript', align: 'left', label: 'รายละเอียด', field: 'descript'},
        /*{ name: 'bonus_01', align: 'right', label: 'รายได้ 20 ชั้น', field: 'bonus_01' },*/
        { name: 'mcode', align: 'center', label: 'รหัสสมาชิก', field: 'mcode' },
        { name: 'rank', align: 'center', label: 'ตำแหน่ง', field: 'rank' },
        { name: 'bonus_01', align: 'right', label: 'แมชชิ่งโบนัส', field: 'bonus_01' },
        //{ name: 'total_bonus', align: 'right', label: 'รวมโบนัส', field: 'total_bonus' },
        //{ name: 'service_charge', align: 'right', label: 'ค่าดำเนินการ', field: 'service_charge'},
        { name: 'tax_bonus', align: 'right', label: 'หักภาษี', field: 'tax_bonus'},
        { name: 'total_bonus_net', align: 'right', label: 'รวมสุทธิ', field: 'total_bonus_net'}
      ],
    }
  },
  methods: {
      refresh (done) {
        setTimeout(() => {
          this.fetchRoundmo();
          done();
        }, 1000)
      },
      /*
      setRoundmoSelected(selected_value) {
        var found = this.roundList.find(function(element) { 
          return element.label == selected_value; 
        });
        this.to_round_selected = found;
      }, 
      */
      setRoundmoSelected() {
        let i = this.roundList.length - 1;
        this.from_round_selected = this.roundList[i];
        this.to_round_selected = this.roundList[0];
        this.getData();
      },
      /* 
      exportPDF: function(round, descript) {
          let self = this;
          openURL(self.url_api + '../pdf/Report.Month.Bonus.Detail.ByMcode.PDF.aspx?round=' + round + '&des='+ descript);    
      },
      */
      exportPDF: function(round, descript, mcode) {
          let self = this;
          let actionUrl = self.url_api + "pdf/Report.Monthly.Bonus.Detail.PDF.aspx";
          let windowName = "";
          let windowFeatures = "";
          let data = {
                        Authorization: self.jwt_token,
                        round: round,
                        descript: descript,
                        mcode: mcode
                     };
          this.windowOpenWithPost(actionUrl, windowName, windowFeatures, data);
      },

      requestRowClick: function(selected_mcode, selected_row) {
          if (this.selected.mcode !== selected_mcode) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
          }
      },
      fetchRoundmo: function () {
        let self = this;
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/Roundmo.ListAll.JSON.aspx"
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          //let round = responseDoc.getElementsByTagName('round_selected')[0].textContent;
          self.roundList = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
          /*
          console.log("result round selected -> :" + round);
          console.log("json roundList:" + responseDoc.getElementsByTagName('json')[0].textContent);
          */
          //self.setRoundmoSelected(round);
          self.setRoundmoSelected();
        })
        .catch(function (error) {
          console.log("Roundmo List Error :" + error);
        });
      },
      filterFnAutoselect (val, update) {
        // call abort() at any time if you can't retrieve data somehow
        setTimeout(() => {
          update(
            () => {
             if (val === '') {
                this.roundOptions = this.roundList
              }
              else {
                const needle = val.toLowerCase()
                this.roundOptions = this.roundList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                //console.log("filter result:" + this.roundOptions[0].label);
                /*
                const result = members.filter((jsonOptions) => {
                  return jsonOptions.label > 25
                })
                this.options = result*/
              }
            },
            // next function is available in Quasar v1.7.4+;
            // "ref" is the Vue reference to the QSelect
            ref => {
              if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
                ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
              }
            }
          )
        }, 300)
      },
      abortFilterFn () {
        // console.log('delayed filter aborted')
      },
      getData: function () {
        var self = this;
        self.loading = true;
        self.rowsPerFetch = self.appRowsPerFetch;
        
        const params = new URLSearchParams();
        params.append('pagesize', self.rowsPerFetch);
        params.append('pageid', self.pageID);
        params.append('from_round', self.from_round_selected.value);
        params.append('to_round', self.to_round_selected.value);
        params.append('mcode', self.mcode);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 0,
          url: self.url_api + 'MemberClient/GetBonusMonthly.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            } else {
              self.datalist = [];
              self.selected = [];
            }
            self.total_record = record_count;
            self.total_page = page_count;
            self.loading = false;
            self.init = true;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            //console.log("Error :" + error + ' round : ' + self.selected.value);
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  },
  mounted: function () {
      this.fetchRoundmo();
      //console.log('Component mounted.');
      //this.getData();
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN')
  },
  watch: {
    pageID: function () {
      if (this.init) {
        this.getData();
      }
    },
    all_mcode: function () {
      if (this.all_mcode) {
        this.mcode = "%";
      } else {
        this.mcode = this.session_mcode;
      }
      this.getData();
    },
    from_round_selected: function () {
      if (this.init) {
        this.getData();
      }
      /*
      if (this.from_round_selected.value == "" || this.to_round_selected.value == "") {
        this.submit_btn_disable = true;
      } else {
        this.submit_btn_disable = false;
      }
      */
    },
    to_round_selected: function () {
      if (this.init) {
        this.getData();
      }
      /*
      if (this.from_round_selected.value == "" || this.to_round_selected.value == "") {
        this.submit_btn_disable = true;
      } else {
        this.submit_btn_disable = false;
      }
      */
    }
  },
  created () {
      //console.log('Component created.');
      store.set("APP_TOOLBAR_TITLE","โบนัสรายเดือน : " + this.session_mcode);
      this.mcode = this.session_mcode;
      this.$q.loading.show();
  }    
}   
