//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'
import MemberIdListDialog from '@/components/MemberIdListDialog.vue'
export default {
  name: "EPointOrderDialog",
  components: {
    MemberIdListDialog,
  },
  props: ["show_status"],

  data () {
    return {
      member_id_list_dialog_show: false,
      submit_btn_disable: false,
      isPwd: true,
      fixed: false,
      to_mcode:"",
      to_name_t:"",
      epoint_password:"",
      epoint_order: 0,
      epoint_balance: 0,
      remark: "",
      full_width_boolean: false,
      dialog_style: "",
      id_no: "",
      name_t: "",
    }
  },
  methods: {
      setIDNo(selected) {
        this.id_no = selected.id_card;
        this.name_t = selected.name_t;
        //alert("cart_info_local.for_id_no : " + this.cart_info_local.for_id_no);
      },
      member_id_list_dialog_hide() {
        this.member_id_list_dialog_show = false;
      },
      setDialogSize() {
        //if (this.$q.screen.width > 1023) { 
        if (this.$q.screen.width > 800) {
          console.log('screen large');
          this.full_width_boolean = false;
          this.dialog_style = "background-color:#f5fafe; width: 500px; max-width: 50vw;";
        }
        else {
          console.log('screen small');
          this.full_width_boolean = true;
          this.dialog_style = "";
        }
      },
      triggerPositive (msg_text) {
        this.$q.notify({
          type: 'positive',
          progress: true,
          message: msg_text,
          classes:'app-font'
        });
      },
      triggerNegative (msg_text) {
        this.$q.notify({
          type: 'negative',
          message: msg_text,
          classes:'app-font'
        })
      },
      clearForm: function() {
          this.to_mcode = "";
          this.to_name_t = "";
          this.epoint_order = 0;
          this.epoint_password = "";
          this.remark = "";
      },
      paddingMcode: function() {
          if (this.to_mcode.length < this.mcode_length) {
            this.to_mcode = this.paddingCode(this.to_mcode);
          }
          this.fetchMcodeName();
      },
      onHide() {
          this.clearForm();
          this.$emit("onHide", "");
          //this.$parent.scode_dialog_show = false;
      },
      onShow() {
          this.id_no = "";
          this.name_t = "";
          this.epoint_order = 0;
          //this.$q.loading.show();
      },
      /*
      fetchEPointBalance: function () {
          let self = this;
          const params = new URLSearchParams();
          params.append('mcode', self.session_mcode);
          self.axios({
            method: "post",
            url: self.url_api + "MemberClient/ePoint.GetBalance.aspx",
            data: params
          })
          .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            self.epoint_balance = responseDoc.getElementsByTagName('epoint')[0].textContent;
            self.$q.loading.hide();
            //console.log("epoint_balance : " + self.epoint_balance);
          })
          .catch(function (error) {
            self.$q.loading.hide();
            console.log("Error :" + error);
          });
      },
      */
      /*
      fetchMcodeName: function () {
          let self = this;
          const params = new URLSearchParams();
          params.append('mcode', self.to_mcode);
          params.append('from_mcode', self.session_mcode);
          self.axios({
            method: "post",
            url: self.url_api + "MemberClient/ePoint.Transfer.GetMemberName.aspx",
            data: params
          })
          .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            self.to_name_t = responseDoc.getElementsByTagName('name_t')[0].textContent;
            //console.log("to_name_t : " + self.to_name_t);
          })
          .catch(function (error) {
            console.log("Error :" + error);
          });
      },
      */
      confirm_post() {
          if (this.epoint_order == 0 && this.name_t !="") {
            return
          } else {
              
                  let self = this;
                  this.$q.dialog({
                      title: "ยืนยันการการเติม",
                      message: 
                        " เติมให้ : <b>" + self.id_no + " - " + self.name_t + "</b><br>"+
                        " ยอดเติม : <b>" + self.formatnumber(parseFloat(self.epoint_order),2) + "</b>" ,
                      persistent: true,
                      html: true,
                      class: "app-font",
                      style: "background-color:#FFFFFF; color:#000000;",
                      ok: {
                        label: "ตกลง",
                        color: 'positive',
                        icon: 'check'
                            },
                            cancel: {
                        label: "ยกเลิก",
                        color: 'negative',
                        icon: 'close'
                      },
                      }).onOk(() => {
                        this.postData();
                        // console.log('>>>> OK')
                      }).onOk(() => {
                        // console.log('>>>> second OK catcher')
                      }).onCancel(() => {
                        // console.log('>>>> Cancel')
                      }).onDismiss(() => {
                        // console.log('I am triggered on both OK and Cancel')
                      })
                      
          }
      },
    
      postData: function () {
          this.submit_btn_disable = true;
          this.$q.loading.show();
          var self = this;
          const params = new URLSearchParams();
          params.append('id_no', self.id_no);
          params.append('epoint', self.epoint_order);
          self.axios({
            method: 'post',
            headers: {
              'Authorization': self.jwt_token
            },   
            timeout: 0,
            url: self.url_api + 'MemberClient/ePoint.Orders.Insert.JSON.aspx',
            data: params
          })
          .then(function (response) {
              //console.log(response);
              let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
              let result = responseDoc.getElementsByTagName('result_value')[0].textContent;
              let result_txt = responseDoc.getElementsByTagName('result_txt')[0].textContent;
              let orders_no = responseDoc.getElementsByTagName('orders_no')[0].textContent;
              if (result == "1") {
                self.$parent.getData();
                self.clearForm();
                self.fixed = false;
                self.$emit("inserted",orders_no);
                setTimeout(() => {
                  self.$q.loading.hide();
                }, 1000);
                self.triggerPositive(result_txt);
              } else {
                setTimeout(() => {
                  self.$q.loading.hide();
                }, 1000);
                self.triggerNegative(result_txt);
              }
              self.submit_btn_disable = false;
          })
          .catch(function (error) {
              console.log("Error :" + error);
              self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
              self.$q.loading.hide();
              self.submit_btn_disable = false;
          });
      }
  },
  mounted () {
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
    },
    "$q.screen.width"() {
      this.setDialogSize()
    }
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      compute_valid_id_no: function() {
        if (this.name_t.length > 0) {
          return true;
        } else {
          return false;
        }
      },
  },
  created () {
    //console.log('Component created.');
    this.setDialogSize();
  }   
 
}
