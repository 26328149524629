//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'
import store from '../store/index'
import MemberPasswordDialog from '@/components/MemberPasswordDialog.vue'
//import EpointPasswordDialog from '@/components/EpointPasswordDialog.vue'
import ExtendBillDialog from '@/components/ExtendBillDialog.vue'

export default {
  components: {
    MemberPasswordDialog,
    //EpointPasswordDialog,
    ExtendBillDialog
  },
  data () {
    return {
      ui_size: "",
      current_session_mcode: "",
      mtype: "",
      bill_dialog_show: false,
      navigationActive: false,
      datalist: [],
      selected: [],
      columns: [
        { name: 'mcode', align: 'center', label: 'รหัสสมาชิก', field: 'mcode'},
        { name: 'rank', align: 'center', label: 'ตำแหน่ง', field: 'rank'},
        { name: 'mdate',  align: 'center', label: 'วันที่สร้างรหัส', field: 'mdate' },
        { name: 'expiredate',  align: 'center', label: 'วันที่พ้นสภาพ', field: 'expiredate' },
        { name: 'sp_code',  align: 'center', label: 'รหัสผู้แนะนำ', field: 'sp_code' },
        { name: 'up_code',  align: 'center', label: 'รหัสอัพไลน์', field: 'up_code' },
        { name: 'side',  align: 'center', label: 'ด้าน', field: 'side' },
        { name: 'step',  align: 'center', label: 'โค้วต้าจับคู่', field: 'step' }
      ],
      member_password_dialog_show: false,
      epoint_password_dialog_show: false,
      mta_expire: false,
      mta_expire_txt: "",
      mta_expire_dd: "",
      mta_expire_mon: "",
      mta_expire_yyyy: "",

      topup_expire: false,
      topup_expire_txt: "",
      topup_expire_dd: "",
      topup_expire_mon: "",
      topup_expire_yyyy: "",

      username: "",
      name_t: "",
      rank: "",
      s_rank: "",
      mdate: "",
      expiredate: "",
      team_l_count: "",
      team_r_count: "",
      sp_code: "",
      sp_name: "",
      up_code: "",
      up_name: "",
      side: "",
      position: "",
      sposition: "",
      ppvtotal: "",
      pv_l: "",
      pv_r: "",
      step : "",
      code_l: "",
      code_r: "",
      epoint: "",
      pagination: {
        rowsPerPage: 0
      },
    }
  },
  methods: {
      setUISize() {
            //if (this.$q.screen.width > 1023) { 
            if (this.$q.screen.width > 800) {
            console.log('screen large');
            this.ui_size = "D";
            }
            else {
            console.log('screen small');
            this.ui_size = "M";
            }
      },
      updateExtend() {
        this.fetchMcodeList();
        this.fetchData();
        this.bill_dialog_show = false;
      }, 
      bill_dialog_hide () {
        this.bill_dialog_show = false;
      },
      activateNavigation () {
        this.navigationActive = true
      },
      deactivateNavigation () {
        this.navigationActive = false
      },
      onKey (evt) {
        if (
          this.navigationActive !== true ||
          [ 33, 34, 35, 36, 38, 40 ].indexOf(evt.keyCode) === -1 ||
          this.$refs.myTable === void 0
        ) {
          return
        }

        evt.preventDefault()

        const { computedRowsNumber, computedRows } = this.$refs.myTable

        if (computedRows.length === 0) {
          return
        }

        const currentIndex = this.selected.length > 0 ? computedRows.indexOf(this.selected[0]) : -1
        const currentPage = this.pagination.page
        const rowsPerPage = this.pagination.rowsPerPage === 0 ? computedRowsNumber : this.pagination.rowsPerPage
        const lastIndex = computedRows.length - 1
        const lastPage = Math.ceil(computedRowsNumber / rowsPerPage)

        let index = currentIndex
        let page = currentPage

        switch (evt.keyCode) {
          case 36: // Home
            page = 1
            index = 0
            break
          case 35: // End
            page = lastPage
            index = rowsPerPage - 1
            break
          case 33: // PageUp
            page = currentPage <= 1 ? lastPage : currentPage - 1
            if (index < 0) {
              index = 0
            }
            break
          case 34: // PageDown
            page = currentPage >= lastPage ? 1 : currentPage + 1
            if (index < 0) {
              index = rowsPerPage - 1
            }
            break
          case 38: // ArrowUp
            if (currentIndex <= 0) {
              page = currentPage <= 1 ? lastPage : currentPage - 1
              index = rowsPerPage - 1
            }
            else {
              index = currentIndex - 1
            }
            break
          case 40: // ArrowDown
            if (currentIndex >= lastIndex) {
              page = currentPage >= lastPage ? 1 : currentPage + 1
              index = 0
            }
            else {
              index = currentIndex + 1
            }
            break
        }

        if (page !== this.pagination.page) {
          this.pagination = {
            ...this.pagination,
            page
          }

          this.$nextTick(() => {
            const { computedRows } = this.$refs.myTable
            this.selected = [ computedRows[Math.min(index, computedRows.length - 1)] ]
          })
        }
        else {
          this.selected = [ computedRows[index] ]
        }
        if (this.selected[0].mcode != this.session_mcode) {
          this.changeMcode(this.selected[0].mcode);
        }
        //this.$emit("onSeleted", this.selected[0].mcode);
      },
      requestRowClick: function(selected_mcode, selected_row) {
          if (this.selected[0].mcode !== selected_mcode) {
            //this.selected.splice(0,1);
            this.selected = [];
            this.selected.push(selected_row);
            this.changeMcode(selected_row.mcode);
            //this.$emit("onSeleted", selected_row);
          }
      },
      refresh (done) {
        setTimeout(() => {
          this.fetchData();
          done();
        }, 1000)
      },
      member_password_dialog_hide () {
        this.member_password_dialog_show = false;
      },
      epoint_password_dialog_hide () {
        this.epoint_password_dialog_show = false;
      },

      fetchMcodeList: function () {
        let self = this;
        self.$q.loading.show();
        self.axios({
          method: "post",
          headers: {
              'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/Member.Mcode.List.JSON.aspx"
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
          self.selected.push(self.datalist.find(datalist => datalist.mcode === self.session_mcode));
          //console.log(responseDoc);
          self.$q.loading.hide();
        })
        .catch(function (error) {
          console.log("Error :" + error);
          self.$q.loading.hide();
        });
      },

      fetchData: function () {
        let self = this;
        const params = new URLSearchParams();
        params.append('mcode', self.current_session_mcode);
        
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/main_page.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          /*
          self.mta_expire_dd = responseDoc.getElementsByTagName('mta_expire_dd')[0].textContent;
          self.mta_expire_mon = responseDoc.getElementsByTagName('mta_expire_mon')[0].textContent;
          self.mta_expire_yyyy = responseDoc.getElementsByTagName('mta_expire_yyyy')[0].textContent;
          self.mta_expire_txt = responseDoc.getElementsByTagName('mta_expire_txt')[0].textContent;
          if (self.mta_expire_txt.length > 0) { self.mta_expire = true } else { self.mta_expire = false }

          self.topup_expire_dd = responseDoc.getElementsByTagName('topup_expire_dd')[0].textContent;
          self.topup_expire_mon = responseDoc.getElementsByTagName('topup_expire_mon')[0].textContent;
          self.topup_expire_yyyy = responseDoc.getElementsByTagName('topup_expire_yyyy')[0].textContent;
          self.topup_expire_txt = responseDoc.getElementsByTagName('topup_expire_txt')[0].textContent;
          if (self.topup_expire_txt.length > 0) { self.topup_expire = true } else { self.topup_expire = false }
          */
          //
          self.rank = responseDoc.getElementsByTagName('rank')[0].textContent;
          switch (self.rank) {
            case "B3": 
              self.mtype = "3";
              break;
            case "B1":
              self.mtype = "2";
              break; 
            case "BS":
              self.mtype = "1";
              break;
            case "B0":
              self.mtype = "0";
              break;  
          }
          self.s_rank = responseDoc.getElementsByTagName('s_rank')[0].textContent;
          self.name_t = responseDoc.getElementsByTagName('name_t')[0].textContent;
          self.mdate = responseDoc.getElementsByTagName('mdate')[0].textContent;
          self.expiredate = responseDoc.getElementsByTagName('expiredate')[0].textContent;
          self.pv_l = responseDoc.getElementsByTagName('pv_l')[0].textContent;
          self.pv_r = responseDoc.getElementsByTagName('pv_r')[0].textContent;
          self.step = responseDoc.getElementsByTagName('step')[0].textContent;
          self.code_l = responseDoc.getElementsByTagName('code_l')[0].textContent;
          self.code_r = responseDoc.getElementsByTagName('code_r')[0].textContent;
          self.team_l_count = responseDoc.getElementsByTagName('team_l_count')[0].textContent;
          self.team_r_count = responseDoc.getElementsByTagName('team_r_count')[0].textContent;
          self.sp_code = responseDoc.getElementsByTagName('sp_code')[0].textContent;
          self.sp_name = responseDoc.getElementsByTagName('sp_name')[0].textContent;
          self.up_code = responseDoc.getElementsByTagName('up_code')[0].textContent;
          self.up_name = responseDoc.getElementsByTagName('up_name')[0].textContent;
          self.side = responseDoc.getElementsByTagName('side')[0].textContent;
          self.epoint = responseDoc.getElementsByTagName('epoint')[0].textContent;
          //self.position = responseDoc.getElementsByTagName('position')[0].textContent;
          //self.sponsor = responseDoc.getElementsByTagName('sponsor')[0].textContent;
          
          //self.ppvtotal = responseDoc.getElementsByTagName('ppvtotal')[0].textContent;
          
          //self.$q.loading.hide();
        })
        .catch(function (error) {
          console.log("Error :" + error);
          self.$q.loading.hide();
        });
      },

      changeMcode : function(mcode) {
          store.set("SESSION_MCODE_LOGIN", mcode);
          this.saveState();
          this.current_session_mcode = mcode;
      },
      saveState () {
        const serializedState = JSON.stringify(this.$store.state)
        sessionStorage.setItem('appBLMemberState', serializedState)
      },
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      pv_lr : function() {
        return  this.pv_l + " (" + this.code_l + ")" + " : " + this.pv_r + " (" + this.code_r + ")";
      },
      sponsor: function() {
        return this.sp_code + " - " + this.sp_name;
      },
      upline: function() {
        return this.up_code + " : " + this.side + " - " + this.up_name;
      }
  },
  watch: {
    /*
    "selected" : function () {
    },
    */
    "$q.screen.width"() {
      this.setUISize()
    }, 
    current_session_mcode : function() {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchMcodeList();
    this.current_session_mcode = this.session_mcode;
    this.fetchData();
  },
  created () {
      //console.log('Component created.');
      this.setUISize();
      store.set("APP_TOOLBAR_TITLE","หน้าหลัก");
      this.$q.loading.show();
  }    
}
