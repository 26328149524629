//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'

export default {
  name: "EPointTransferDialog",
  props: ["show_status"],

  data () {
    return {
      submit_btn_disable: false,
      isPwd: true,
      fixed: false,
      loading: false,
      epoint_password:"",
      epoint_withdraw: 0,
      epoint_balance: 0,
      acc_no:"",
      acc_type:"",
      acc_name:"",
      bank_code:"",
      bank_name:"",
      bank_branch:"",
      full_width_boolean: false,
      dialog_style: "",
    }
  },
  methods: {
      setDialogSize() {
        //if (this.$q.screen.width > 1023) { 
        if (this.$q.screen.width > 800) {
          console.log('screen large');
          this.full_width_boolean = false;
          this.dialog_style = "background-color:#f5fafe; width: 600px; max-width: 60vw;";
        }
        else {
          console.log('screen small');
          this.full_width_boolean = true;
          this.dialog_style = "";
        }
      },
      triggerPositive (msg_text) {
        this.$q.notify({
          type: 'positive',
          progress: true,
          message: msg_text,
          classes:'app-font'
        });
      },
      triggerNegative (msg_text) {
        this.$q.notify({
          type: 'negative',
          message: msg_text,
          classes:'app-font'
        })
      },
      clearForm: function() {
        this.epoint_withdraw = 0;
        this.epoint_password = "";
      },
      paddingMcode: function() {
        if (this.to_mcode.length < this.mcode_length) {
          this.to_mcode = this.paddingCode(this.to_mcode);
        }
        this.fetchMcodeName();
      },
      onHide() {
        this.clearForm();
        this.$emit("onHide", "");
      },
      onShow() {
        this.$q.loading.show();
        this.fetchBankInfo();
      },
      fetchBankInfo: function () {
        let self = this;
        const params = new URLSearchParams();
        params.append('mcode', self.session_mcode);
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/ePoint.GetBankInfo.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.bank_code = responseDoc.getElementsByTagName('bankcode')[0].textContent;
          self.bank_name = responseDoc.getElementsByTagName('bankname')[0].textContent;
          self.bank_branch = responseDoc.getElementsByTagName('bankbranch')[0].textContent;
          self.acc_no = responseDoc.getElementsByTagName('acc_no')[0].textContent;
          self.acc_type = responseDoc.getElementsByTagName('acc_type')[0].textContent;
          self.acc_name = responseDoc.getElementsByTagName('acc_name')[0].textContent;
          self.fetchEPointBalance();
          //console.log("epoint_balance : " + self.epoint_balance);
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      fetchEPointBalance: function () {
        let self = this;
        const params = new URLSearchParams();
        params.append('mcode', self.session_mcode);
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },  
          url: self.url_api + "MemberClient/ePoint.GetBalance.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.epoint_balance = responseDoc.getElementsByTagName('epoint')[0].textContent;
          self.$q.loading.hide();
          //console.log("epoint_balance : " + self.epoint_balance);
        })
        .catch(function (error) {
          self.$q.loading.hide();
          console.log("Error :" + error);
        });
      },
      fetchMcodeName: function () {
        let self = this;
        const params = new URLSearchParams();
        params.append('mcode', self.to_mcode);
        params.append('from_mcode', self.session_mcode);
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/ePoint.Transfer.GetMemberName.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.to_name_t = responseDoc.getElementsByTagName('name_t')[0].textContent;
          //console.log("to_name_t : " + self.to_name_t);
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      confirm_post() {
          if (this.epoint_withdraw == 0 || this.epoint_password =="") {
            return
          } else {
            if (parseFloat( this.replaceAll(this.epoint_withdraw,",","") ) <= parseFloat(  this.replaceAll(this.epoint_balance,",","")  )) {
              let self = this;
              this.$q.dialog({
                title: "ยืนยันการการถอน",
                message: " โอนไปยังธนาคาร-สาขา : <b>" + self.bank_info + " - " + self.bank_branch + "</b></br>" +
                        " เลขที่บัญชี-ชื่อบัญชี : <b>" + self.acc_no + " - " + self.acc_name + "</b></br>" +
                        " ยอดรวม : <b>" + self.formatnumber(parseFloat(self.epoint_withdraw),2) + "</b>",
                persistent: true,
                html: true,
                class: "app-font",
                style: "background-color:#FFFFFF; color:#000000;",
                ok: {
                  label: "ตกลง",
                  color: 'positive',
                  icon: 'check'
                },
                cancel: {
                  label: "ยกเลิก",
                  color: 'negative',
                  icon: 'close'
                },
              }).onOk(() => {
                this.postData();
                // console.log('>>>> OK')
              }).onOk(() => {
                // console.log('>>>> second OK catcher')
              }).onCancel(() => {
                // console.log('>>>> Cancel')
              }).onDismiss(() => {
                // console.log('I am triggered on both OK and Cancel')
              })
            } else {
                this.triggerNegative("ยอด e-Point มีไม่พอ");
            }
          }
      },
      postData: function () {
        this.submit_btn_disable = true;
        this.$q.loading.show();
        var self = this;
        const params = new URLSearchParams();
        params.append('withdraw_type', "7");
        params.append('mcode', self.session_mcode);
        params.append('acc_no', self.acc_no);
        params.append('bankcode', self.bank_code);
        params.append('epoint', self.epoint_withdraw);
        params.append('password', self.epoint_password);
        params.append('remark', self.acc_name);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 0,
          url: self.url_api + 'MemberClient/ePoint.Withdraw.Insert.JSON.aspx',
          data: params
        })
        .then(function (response) {
            //console.log(response);
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let result = responseDoc.getElementsByTagName('result_value')[0].textContent;
            let result_txt = responseDoc.getElementsByTagName('result_txt')[0].textContent;
            if (result == "1") {
                self.$parent.getData();
                self.clearForm();
                self.fixed = false;
                setTimeout(() => {
                  self.$q.loading.hide();
                }, 1000);
                self.triggerPositive(result_txt);
            } else {
                setTimeout(() => {
                  self.$q.loading.hide();
                }, 1000);
                self.triggerNegative(result_txt);
            }
            self.fetchEPointBalance();
            self.submit_btn_disable = false;

        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
            self.$q.loading.hide();
            self.submit_btn_disable = false;

        });
      }
  },
  mounted () {
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
    },
    "$q.screen.width"() {
      this.setDialogSize()
    }
  },
  computed: {
      bank_info: function () {
        return this.bank_code + " - "  + this.bank_name;
      },
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN')
  },
  created () {
    //console.log('Component created.');
    this.setDialogSize();
  }    
 
}
