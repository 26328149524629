import { render, staticRenderFns } from "./App.vue?vue&type=template&id=1b105e8c&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&scope=true&lang=css&"
import style1 from "./App.vue?vue&type=style&index=1&lang=sass&scope=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QLayout,QHeader,QBar,QIcon,QSpace,QBtn,QToolbar,QToolbarTitle,QDrawer,QList,QItem,QItemSection,QItemLabel,QSeparator,QExpansionItem,QPageContainer,QDialog,QCard,QLinearProgress,QCardSection,QAvatar,QCardActions,QTable,Ripple,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QLayout,QHeader,QBar,QIcon,QSpace,QBtn,QToolbar,QToolbarTitle,QDrawer,QList,QItem,QItemSection,QItemLabel,QSeparator,QExpansionItem,QPageContainer,QDialog,QCard,QLinearProgress,QCardSection,QAvatar,QCardActions,QTable})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple,ClosePopup})
