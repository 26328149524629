//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RegistrationList from '@/components/RegistrationList.vue'
import RegistrationForm from '@/components/RegistrationForm.vue'
import { get } from "vuex-pathify"
import store from '../store/index'

export default {
  name:'RegistrationView',
  components: {
    RegistrationList,
    RegistrationForm
  },
  data () {
    return {
      mcode:"",
      up_code: "",
      side: "",
      splitterLimit: [3, 97],
      splitterLimitDesktop: [53, 68],
      splitterLimitMobile: [3, 97],
      splitterModel: 97, // start at 25%,
      splitterModelDesktop: 68,
      splitterModelMobile: 97,
      disable_value: false,
      showSlot: false
    }
  },
  methods: {
    setsplitterSize: function () {
        if (this.$q.screen.width > 800) {
          console.log('screen large');
          this.splitterLimit = this.splitterLimitDesktop;
          this.splitterModel = this.splitterModelDesktop;
        }
        else {
          console.log('screen small');
          this.splitterLimit = this.splitterLimitMobile;
          this.splitterModel = this.splitterModelMobile;
        }
    },
    getSelectedValue(selected_value) {
      this.mcode = selected_value;
      //console.log("Child Selected Value :" + selected_value);
      //this.$refs.mcode.value = this.mcode;
    },
    disabledDataList() {
      this.$refs.memberlist.table_disabled = true;
      this.disable_value = true;
    },
    enabledDataList() {
      this.$refs.memberlist.table_disabled = false;
      this.disable_value = false;
      //this.$refs.memberlist.removeAttribute("disabled");
      //this.$refs.attr.remove("disabled");
    },
    getFormMode(mode) {
      if (mode == "edit") {
        this.disabledDataList();
      } else {
        this.enabledDataList();
      }
    },
    insertFinish() {
      this.enabledDataList();
      this.up_code = "";
      this.side = "";
      this.$refs.memberlist.fetchDataList();
    },
    init_mcode(mcode) {
      this.mcode = mcode;
      //this.$refs.memberform.fetchData();
    },
    saveState () {
      const serializedState = JSON.stringify(this.$store.state)
      sessionStorage.setItem('appBLMemberState', serializedState)
    },

  },
  mounted: function () {
    //this.showSlot = true;
    setTimeout(() => this.showSlot = true, 500);
    
    //this.$refs.memberlist.setAttribute("style", "display:block");
    //alert(this.$refs.mcode.value);
    //this.disabledDataList();
  },
  watch: {
    mcode: function () {
      //console.log("mcode change to " + this.mcode );
    },
    "$q.screen.width"() {
      this.setsplitterSize();
    }
  },
  computed: {
     init_up_code: get('INIT_UP_CODE'),
     init_side: get('INIT_SIDE')
  },
  created () {
      //console.log('Component created.');
      this.setsplitterSize();
      store.set("APP_TOOLBAR_TITLE","สร้างรหัสสมาชิก");
      //this.$q.loading.show();
      this.up_code = this.init_up_code;
      this.side = this.init_side;
      store.set("INIT_UP_CODE","");
      store.set("INIT_SIDE","");
      this.saveState();
      //alert("init upcode : " + this.up_code);
  }    

}
